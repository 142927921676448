import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import reducer from './store/reducer';
import common_reducer from './store/common_reducer';
import profile_info_reducers from './store/profile_info_reducer'
import extra_curricular_reducer from './store/extra_curricular_reducer';
import experience_reducer from './store/experience_reducer';
import education_reducer from './store/education_reducer';
import skills_reducer from './store/skills_reducer';
import languages_reducer from './store/languages_reducers';


const rootReducers = combineReducers({
    common_reducer: common_reducer,
    profile_info_reducers: profile_info_reducers,
    extra_curricular_reducer: extra_curricular_reducer,
    experience_reducer: experience_reducer,
    education_reducer: education_reducer,
    skills_reducer: skills_reducer,
    languages_reducer: languages_reducer
})



const store = createStore(rootReducers);

//bootstrap npm package


ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
