import React, { Component } from 'react';
import './student_form.css';
import { connect } from 'react-redux';
import Axios from 'axios';
import Sidebar from './sidebar/sidebar'
import Navbar from './../UI/navbar';
import Forms from './forms/forms';
import Progressbar from './progress_bar/progress_bar';
import { data_withvalidation } from './forms/form_validation';
import { firebase_database_base_url } from './../env/firebase '



class Student_form extends Component {
    componentDidMount() {
        Axios.get(firebase_database_base_url + 'users.json?auth=' + localStorage.getItem('token') + '&orderBy="userId"&equalTo="' + localStorage.getItem('userId') + '"')
            .then(res => {
                const test = (Object.keys(res.data))
                if (localStorage.getItem('token') !== null) {
                    this.database_validaty(res.data[test])
                }
            })
            .catch(err => err)
    }

    database_validaty = (data) => {
        let updated_data = {
            profile_info: data.profile_info,
            extra_curricular: data.extra_curricular,
            experience: data.experience,
            education: data.education,
            skills: data.skills,
            languages: data.languages
        }
        if (updated_data.profile_info === undefined) {
            updated_data = {
                ...updated_data,
                profile_info: this.props.profile_info
            }
        }
        if (updated_data.extra_curricular === undefined) {
            updated_data = {
                ...updated_data,
                extra_curricular: []
            }
        }
        if (updated_data.experience === undefined) {
            updated_data = {
                ...updated_data,
                experience: []
            }
        }
        if (updated_data.education === undefined) {
            updated_data = {
                ...updated_data,
                education: this.props.education
            }
        }

        if (updated_data.education.certificate === undefined) {
            updated_data = {
                ...updated_data,
                education: {
                    ...updated_data.education,
                    certificate: this.props.education.certificate
                }
            }
        }
        if (updated_data.skills === undefined) {
            updated_data = {
                ...updated_data,
                skills: []
            }
        }
        if (updated_data.languages === undefined) {
            updated_data = {
                ...updated_data,
                languages: this.props.languages
            }
        }
        this.props.onuploaddata(updated_data)

    }

    componentDidUpdate() {
        //profile info green dot validation start
        if (this.props.profile_info.student_id !== '' && this.props.profile_info.firstname !== '' &&
            this.props.profile_info.lastname !== '' && this.props.profile_info.mobileno !== '' &&
            this.props.profile_info.dob !== '' && this.props.profile_info.email !== '' &&
            this.props.profile_info.collegename !== '') { this.props.onsidebar_greendot(true, 'proinfo') }
        else {
            this.props.onsidebar_greendot(false, 'proinfo')
        }
        //profile info green dot validation end

        //experience green dot validation start
        if (this.props.experience.length >= 1) {
            this.props.onsidebar_greendot(true, 'expe')
        }
        else {
            this.props.onsidebar_greendot(false, 'expe')
        }
        //experience green dot validation end

        //extra curricular green dot validation start

        if ((this.props.extra_curricular.length >= 2) || ((this.props.extra_curricular.length >= 1 &&
            (this.props.extra_curricular[0].cause !== ''
                || this.props.extra_curricular[0].organization !== '')))) {
            this.props.onsidebar_greendot(true, 'ex_curr')
        }
        else {
            this.props.onsidebar_greendot(false, 'ex_curr')

        }

        //extra curricular green dot validation end

        if (this.props.languages.length > 0) {
            this.props.onsidebar_greendot(true, 'language')
        }
        else {
            this.props.onsidebar_greendot(false, 'language')

        }

        if (this.props.skills.length > 0) {
            this.props.onsidebar_greendot(true, 'skills')
        }
        else {
            this.props.onsidebar_greendot(false, 'skills')

        }
        if (this.props.education.graduation.gr_year_graduation !== '' && this.props.education.graduation.gr_overall_percentage !== '' &&
            this.props.education.graduation.gr_current_semster !== '' && this.props.education.tenth.te_year_completion !== '' &&
            this.props.education.tenth.te_overall_percentage !== '' && this.props.education.tenth.te_math_score !== '' &&
            this.props.education.tenth.te_physics_score !== '' && this.props.education.twelfth.tw_year_completion !== '' &&
            this.props.education.twelfth.tw_overall_percentage !== '' && this.props.education.twelfth.tw_math_score !== '' &&
            this.props.education.twelfth.tw_physics_score !== '') {
            this.props.onsidebar_greendot(true, 'educ')
        }
        else {
            this.props.onsidebar_greendot(false, 'educ')
        }

    }



    submitdata = () => {
        // this.props.history.push('/test')
        let data = {
            profile_info: this.props.profile_info,
            extra_curricular: [...this.props.extra_curricular],
            experience: [...this.props.experience],
            education: this.props.education,
            skills: [...this.props.skills],
            languages: [...this.props.languages]
        }
        Axios.patch(firebase_database_base_url + 'users/' + this.props.user_databaseId + '.json?auth=' + this.props.token, data_withvalidation(data))
            .then(res => {
                alert('User Data Saved')
            })
            .catch(err => err)
    }

    formsubmit = (e) => {
        e.preventDefault();
    }


    render() {

        return (
            <div>
                <form onSubmit={(e) => this.formsubmit(e)}>
                    <Navbar />

                    <div className="profile-comp">
                        <div className="narrow profile-align">
                            <span>Profile Completeness</span>
                            <Progressbar />
                            <span> {Math.round(this.props.form_progress_counter)} % Complete</span>
                            <div>
                                <button style={{ marginRight: '10px' }} className="btn btn-primary btn-trans btn-trans-findjobs">Find Jobs</button>
                                <button className="btn btn-primary btn-submit" onClick={this.submitdata}>Save</button>
                            </div>

                        </div>
                    </div>
                    <div className="content">
                        <Sidebar />
                        <Forms />
                    </div>

                </form>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userId: state.common_reducer.userId,
        user_databaseId: state.common_reducer.user_databaseId,
        profile_info: state.profile_info_reducers.profile_info,
        extra_curricular: state.extra_curricular_reducer.extra_curricular,
        extra_curricular_item: state.extra_curricular_reducer.extra_curricular_item, //for componenedidupdate
        experience: state.experience_reducer.experience,
        experience_item: state.experience_reducer.experience_item, //for componenedidupdate
        education: state.education_reducer.education,
        skills: state.skills_reducer.skills,
        languages: state.languages_reducer.languages,
        form_progress_counter: state.common_reducer.form_progress_counter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onuploaddata: ((upload_data) => dispatch({ type: 'upload_data', value: { upload_data: upload_data } })),
        onsidebar_greendot: ((status, formname) => dispatch({ type: 'sidebar_greendot', value: { status: status, formname: formname } })),   //for green dot updation
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Student_form);