import React, { Component } from 'react';
import './education.css';
import { connect } from 'react-redux';
import ModernDatepicker from 'react-modern-datepicker';


class Education extends Component {

    state = {
        gr_overall_percentageError: false,
        gr_no_uncleared_arreareError: false,
        te_overall_percentageError: false,
        tw_overall_percentageError: false
    }


    onlynumber = (e, property) => {
        const re = /[0-9A-F:]+/g;
        if (!re.test(e.key)) {
            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }
    }

    numberwithsp = (e, property) => {
        const re = /^[0-9-+.]*$/;
        if (!re.test(e.key)) {
            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }
    }

    curentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }




    addlistitem = () => {

        let certificate = [...this.props.education.certificate]
        if (this.props.education.certificate.length === 0) {
            certificate.unshift({
                ...this.props.education_certificate_item
            })
        }

        else if (this.props.education.certificate[0].education_type || this.props.education.certificate[0].year_certification ||
            this.props.education.certificate[0].certification_subject || this.props.education.certificate[0].certifying_organization ||
            this.props.education.certificate[0].score
        ) {
            certificate.unshift({
                ...this.props.education_certificate_item
            })

        }
        this.props.oneducation_certificate(certificate)
    }

    removelistitem = (education_certificate) => {
        let temp = this.props.education.certificate.filter((item) => item !== education_certificate);
        this.props.oneducation_certificate(temp)
    }

    listitemchange = (itemkey, itemvalue, education_certificate) => {
        let listitem = [...this.props.education.certificate]
        listitem.map(listitem => {
            if (listitem === education_certificate) {
                listitem[itemkey] = itemvalue
            }
        })
        this.props.oneducation_certificate(listitem)
    }


    render() {
        const total_array_elememt = this.props.education.certificate.length;
        let list = [];
        list = this.props.education.certificate

        if (this.props.education_certificate_latest && total_array_elememt > 0) {
            list = list.slice(0, total_array_elememt - 1)
        }
        let show = list.map((education_certificate, index) => (
            <div key={index}>
                <div className="topborder"></div>
                <div className="removebtn">
                    <button type="button" className="btn btn-primary btn-trans" onClick={() => this.removelistitem(education_certificate)} > Remove</button>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="education_type">Certification</label>
                        <input type="text" className="form-control" id="education_type" value={education_certificate.education_type}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, education_certificate)}
                            placeholder="Certification" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="year_certification">Year of Completion*</label>
                        <ModernDatepicker
                            className="form-control"
                            date={education_certificate.year_certification}
                            format={'DD-MM-YYYY'}
                            showBorder
                            id="year_certification"
                            onChange={(e) => this.listitemchange('year_certification', e, education_certificate)}
                            placeholder={'Year of Completion'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="certification_subject">Certification Subject*</label>
                        <input type="text" className="form-control" id="certification_subject" value={education_certificate.certification_subject}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, education_certificate)}
                            placeholder="Certification Subject" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="certifying_organization">Certifying Organization*</label>
                        <input type="text" className="form-control" id="certifying_organization" value={education_certificate.certifying_organization}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, education_certificate)}
                            placeholder="Certifying Organization" />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="score">Score</label>
                        <input type="text" className="form-control" id="score" value={education_certificate.score}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, education_certificate)}
                            placeholder="Score" />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                </div>
            </div>
        ))
        return (
            <div>
                <p className="profile-info-header">Education</p>

                {/* graduation start */}

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_education_type">Education Type*</label>
                        <select className="form-control" id="gr_education_type" value={this.props.education.graduation.gr_education_type}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}>
                            <option value="Graduate Degree">Graduate Degree</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_year_graduation">Year of Graduation*</label>
                        <ModernDatepicker
                            className="form-control"
                            date={this.props.education.graduation.gr_year_graduation}
                            format={'DD-MM-YYYY'}
                            showBorder
                            id="gr_year_graduation"
                            onChange={(e) => this.props.oneducation('gr_year_graduation', e, 'graduation')}
                            placeholder={'Year of Completion'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_degree_program">Degree Program*</label>
                        <select className="form-control" id="gr_degree_program" value={this.props.education.graduation.gr_degree_program}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}>
                            <option value="Banchelor’s_in_engineering">Banchelor’s in Engineering (BE)</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_specialization">Specialization*</label>
                        <select className="form-control" id="gr_specialization" value={this.props.education.graduation.gr_specialization}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}>
                            <option>Select Specialization</option>
                            <option value="Industrial Engineering">Industrial Engineering</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_overall_percentage">Overall Percentage*</label>
                        <input type="text" className="form-control" id="gr_overall_percentage" value={this.props.education.graduation.gr_overall_percentage}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}
                            placeholder="Overall Percentage" onKeyPress={(e) => this.numberwithsp(e, 'gr_overall_percentageError')} />
                        <p style={{
                            opacity: this.state.gr_overall_percentageError ? '1' : '0'
                        }} className="error">Enter only digits and point</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_current_semster">Current Semester*</label>
                        <select className="form-control" id="gr_current_semster" value={this.props.education.graduation.gr_current_semster}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}>
                            <option >Select Semester</option>
                            <option value="1st Semester">1st Semester</option>
                            <option value="2nd Semester">2nd Semester</option>
                            <option value="3rd Semester">3rd Semester</option>
                            <option value="4th Semester">4th Semester</option>
                            <option value="5th Semester">5th Semester</option>
                            <option value="6th Semester">6th Semester</option>
                            <option value="7th Semester">7th Semester</option>
                            <option value="8th Semester">8th Semester</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_do_uncleared_arreare">Do you have any uncleared arrears?</label>
                        <select className="form-control" id="gr_do_uncleared_arreare" value={this.props.education.graduation.gr_do_uncleared_arreare}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}>
                            <option value="NO">No</option>
                            <option value="YES">Yes</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="gr_no_uncleared_arreare">Specify number of uncleared arrears</label>
                        <input type="text" className="form-control" id="gr_no_uncleared_arreare" value={this.props.education.graduation.gr_no_uncleared_arreare}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'graduation')}
                            placeholder="Specify number of uncleared arrears" onKeyPress={(e) => this.onlynumber(e, 'gr_no_uncleared_arreareError')} />
                        <p style={{
                            opacity: this.state.gr_no_uncleared_arreareError ? '1' : '0'
                        }} className="error">Enter only digits</p>
                    </div>
                </div>
                <div className="topborder">

                </div>

                {/* graduation end */}
                {/* tenth start */}

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="te_education_type">Education Type*</label>
                        <select className="form-control" id="te_education_type" value={this.props.education.tenth.te_education_type}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'tenth')}>
                            <option value="10th Standard">10th Standard</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="te_year_completion">Year of Completion*</label>
                        <ModernDatepicker
                            className="form-control"
                            date={this.props.education.tenth.te_year_completion}
                            format={'DD-MM-YYYY'}
                            showBorder
                            id="te_year_completion"
                            onChange={(e) => this.props.oneducation('te_year_completion', e, 'tenth')}
                            placeholder={'Year of Graduation'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="te_board">Board*</label>
                        <select className="form-control" id="te_board" value={this.props.education.tenth.te_board}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'tenth')}>
                            <option value="CBSE">CBSE</option>
                            <option value="ICSE">ICSE</option>
                            <option value="State Board">State Board</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="te_overall_percentage">Overall Percentage*</label>
                        <input type="text" className="form-control" id="te_overall_percentage" value={this.props.education.tenth.te_overall_percentage}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'tenth')}
                            placeholder="Overall Percentage" onKeyPress={(e) => this.numberwithsp(e, 'te_overall_percentageError')} />
                        <p style={{
                            opacity: this.state.te_overall_percentageError ? '1' : '0'
                        }} className="error">Enter only digits and point</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="te_math_score">Mathemetics Score*</label>
                        <input type="text" className="form-control" id="te_math_score" value={this.props.education.tenth.te_math_score}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'tenth')}
                            placeholder="Mathemetics Score" />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="te_physics_score">Physics Score*</label>
                        <input type="text" className="form-control" id="te_physics_score" value={this.props.education.tenth.te_physics_score}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'tenth')}
                            placeholder="Physics Score" />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                </div>
                <div className="topborder">

                </div>

                {/* tenth end */}
                {/* twelfth start */}
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="tw_education_type">Education Type*</label>
                        <select className="form-control" id="tw_education_type" value={this.props.education.twelfth.tw_education_type}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'twelfth')}>
                            <option>12th Standard</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="tw_year_completion">Year of Completion*</label>
                        <ModernDatepicker
                            className="form-control"
                            date={this.props.education.twelfth.tw_year_completion}
                            format={'DD-MM-YYYY'}
                            showBorder
                            id="tw_year_completion"
                            onChange={(e) => this.props.oneducation('tw_year_completion', e, 'twelfth')}
                            placeholder={'Year of Completion'}
                            primaryColor={'#00b6aa'}
                            secondaryColor={'white'}
                            primaryTextColor={'black'}
                        />
                        <p className="error error-op">sghdcvv</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="tw_board">Board*</label>
                        <select className="form-control" id="tw_board" value={this.props.education.twelfth.tw_board}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'twelfth')}>
                            <option value="CBSE">CBSE</option>
                            <option value="ICSE">ICSE</option>
                            <option value="State Board">State Board</option>
                        </select>
                        <p className="error error-op">sghdcvv</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="tw_overall_percentage">Overall Percentage*</label>
                        <input type="text" className="form-control" id="tw_overall_percentage" value={this.props.education.twelfth.tw_overall_percentage}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'twelfth')}
                            placeholder="Overall Percentage" onKeyPress={(e) => this.numberwithsp(e, 'tw_overall_percentageError')} />
                        <p style={{
                            opacity: this.state.tw_overall_percentageError ? '1' : '0'
                        }} className="error">Enter only digits and point</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="tw_math_score">Mathemetics Score*</label>
                        <input type="text" className="form-control" id="tw_math_score" value={this.props.education.twelfth.tw_math_score}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'twelfth')} placeholder="Mathemetics Score" />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="tw_physics_score">Physics Score*</label>
                        <input type="text" className="form-control" id="tw_physics_score" value={this.props.education.twelfth.tw_physics_score}
                            onChange={(e) => this.props.oneducation(e.target.id, e.target.value, 'twelfth')}
                            placeholder="Physics Score" />
                        <p className="error error-op">sjhcbgh</p>
                    </div>
                </div>
                <div className="topborder">

                </div>

                {/* twelfth end */}
                {/* certificate start */}
                <div style={{display:'flex'}}>
                    <span className="add_certificate_text">Add Certificate</span>
                    <button style={{ marginLeft: '65%' }} type="button" onClick={this.addlistitem} className="btn btn-primary btn-add"> + Add</button>
                </div>
                {show}
                <div className="custom_btn_div">
                    <button type="button" onClick={() => this.curentstateHandler('proinfo')} className="btn btn-primary btn-trans">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="btn-arrow-back">
                                        &larr;
                                    </td>
                                    <td >
                                        Profile-Info
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button type="button" onClick={() => this.curentstateHandler('expe')} className="btn btn-primary btn-trans">
                        <table>
                            <tbody>
                                <tr>
                                    <td >
                                        Experience
                                    </td>
                                    <td className="btn-arrow-for">
                                        &rarr;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        education: state.education_reducer.education,
        education_certificate_latest: state.education_reducer.education_certificate_latest,
        education_certificate_item: state.education_reducer.education_certificate_item,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } })),
        oneducation: ((itemkey, itemvalue, type) => dispatch({ type: 'education', value: { itemkey: itemkey, itemvalue: itemvalue, type: type } })),
        oneducation_certificate: ((education_certificate) => dispatch({
            type: 'education_certificate',
            value: { education_certificate: education_certificate }
        })),
        oneducation_certificate_latest: ((education_certificate_latest) => dispatch({
            type: 'education_certificate_latest',
            value: { education_certificate_latest: education_certificate_latest }
        })),
        oneducation_certificate_item: ((education_certificate_item) => dispatch({
            type: 'education_certificate_item',
            value: { education_certificate_item: education_certificate_item }
        }))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Education);