import React from 'react';
import H2pdf from './main_content.js';
import './style.css';
import script from './javascript.js';
import download_icon from './download.svg';

class Html2Pdf extends React.Component{

	state = {
		userid: "-Lo9oUB1w5Fd0uKHRR-c",
		data: null
	};

	componentDidMount= () => {
		fetch(`https://test-ade62.firebaseio.com/users/${this.state.userid}.json`)
		.then(response => response.json())
		.then(data => this.setState({data: data}))
		.catch(err => console.log(err));
	}

	render()
	{	
		console.log(this.state.data);
		return(<Aux>
			<button onClick= {script} id= "print"><img src= {download_icon} alt= "download PDF"/></button>
			{this.state.data ? <H2pdf data= {this.state.data}/> : null}
		</Aux>)
	}

}

let Aux= props => props.children;

export default Html2Pdf;
