import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class Logout extends Component {
    componentDidMount() {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate')
        localStorage.removeItem('userId')
        localStorage.removeItem('user_databaseId')
        localStorage.removeItem('refreshToken')
        this.props.onAuthState(null, null, null, null)
    }

    render() {
        return <Redirect to="/" />;
    }
}



const mapDispatchToProps = dispatch => {
    return {
        onAuthState: ((token, userId, user_databaseId, expirationDate) => dispatch({
            type: 'authcheck', value: {
                token: token, userId: userId,
                user_databaseId: user_databaseId,
                expirationDate: expirationDate
            }
        }))

    }
};



export default connect(null, mapDispatchToProps)(Logout);