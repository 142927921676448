export const data_withvalidation = (data) => {

    let validated_data = { ...data }

    var re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;
    

   

    //profile info email
    if (!re.test(validated_data.profile_info.email)) {
        validated_data = {
            ...validated_data,
            profile_info: {
                ...validated_data.profile_info,
                email: ''
            }
        }
    }


    //profile info contact number
    if (validated_data.profile_info.mobileno.length !== 10) {
        validated_data = {
            ...validated_data,
            profile_info: {
                ...validated_data.profile_info,
                mobileno: ''
            }
        }
    }


    return validated_data
}


