import React, { Component } from 'react';
import './extra-curr.css';
import { connect } from 'react-redux';


class Extra_Curricular extends Component {
    curentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        if (this.props.extra_curricular.length < 1) {
            let extra_curricular = [...this.props.extra_curricular]

            extra_curricular.unshift({
                ...this.props.extra_curricular_item
            })
            this.props.onextracurricular(extra_curricular)
        }
    }


    addlistitem = () => {

        if (this.props.extra_curricular[0].cause !== '' || this.props.extra_curricular[0].organization !== '') {
            let extra_curricular = [...this.props.extra_curricular]
            extra_curricular.unshift({
                ...this.props.extra_curricular_item
            })
            this.props.onextracurricular(extra_curricular)
        }
    }

    removelistitem = (extra_curricular) => {
        let temp = this.props.extra_curricular.filter((item) => item !== extra_curricular);
        this.props.onextracurricular(temp)
    }

    listitemchange = (itemkey, itemvalue, extra_curricular) => {
        let listitem = [...this.props.extra_curricular]
        listitem.map(listitem => {
            if (listitem === extra_curricular) {
                listitem[itemkey] = itemvalue
            }
        })
        this.props.onextracurricular(listitem)
    }




    render() {

        let list = [];
        list = [...this.props.extra_curricular]

        let show = list.map((extra_curricular, index) => (
            <div key={index}>
                <div className="removebtn">
                    {this.props.extra_curricular.length > 1 ? < button type="button" className="btn btn-primary btn-trans" onClick={() => this.removelistitem(extra_curricular)} > Remove</button> : null}
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Activity Type*</label>
                        <select className="form-control" id="activity_type" value={extra_curricular.activity_type}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, extra_curricular)}>
                            <option value="Volunter">Volunteer</option>
                            <option value="Sports">Sports</option>
                            <option value="Art and Culture">Art and Culture</option>
                            <option value="Other">Other</option>
                        </select>
                        <p className="error error-op">Error</p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Cause</label>
                        <input type="text" className="form-control" id="cause" value={extra_curricular.cause}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, extra_curricular)} placeholder="Cause" />
                        <p className="error error-op">Error</p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Organization*</label>
                        <input type="text" className="form-control" id="organization" value={extra_curricular.organization}
                            onChange={(e) => this.listitemchange(e.target.id, e.target.value, extra_curricular)} placeholder="Organization" />
                        <p className="error error-op">Error</p>
                    </div>
                </div>
                <div className="topborder"></div>

            </div >
        ))


        return (
            <div>
                <p className="profile-info-header">Extra-Curricular
                 <button style={{ marginLeft: 'auto' }} type="button" onClick={this.addlistitem} className="btn btn-primary btn-add"> + Add</button></p>
                {show}
                <div className="custom_btn_div">
                    <button type="button" onClick={() => this.curentstateHandler('skills')} className="btn btn-primary btn-trans">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="btn-arrow-back">
                                        &larr;
                                </td>
                                    <td >
                                        Skills
                               </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                    <button type="button" onClick={() => this.curentstateHandler('language')} className="btn btn-primary btn-trans">
                        <table>
                            <tbody>
                                <tr>
                                    <td >
                                        Language
                                    </td>
                                    <td className="btn-arrow-for">
                                        &rarr;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        extra_curricular: state.extra_curricular_reducer.extra_curricular,
        extra_curricular_latest: state.extra_curricular_reducer.extra_curricular_latest,
        extra_curricular_item: state.extra_curricular_reducer.extra_curricular_item
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } })),
        onextracurricular: ((extra_curricular) => dispatch({
            type: 'extra_curricular',
            value: { extra_curricular: extra_curricular }
        })),
        onextra_curricular_latest: ((extra_curricular_latest) => dispatch({
            type: 'extra_curricular_latest',
            value: { extra_curricular_latest: extra_curricular_latest }
        })),
        onextra_curricular_item: ((extra_curricular_item) => dispatch({
            type: 'extra_curricular_item',
            value: { extra_curricular_item: extra_curricular_item }
        }))
    };
};




export default connect(mapStateToProps, mapDispatchToProps)(Extra_Curricular);