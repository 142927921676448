import html2pdf from 'html2pdf.js';
import fliprLogo from './fliprlogo.js';

let script= () => {

	let outer_height= el => {
	var width = el.offsetHeight;
	var style = getComputedStyle(el);
	width += parseInt(style.marginTop) + parseInt(style.marginBottom);
	return width;
}

let work_items_height= [...document.querySelectorAll(".cv_mainContent-innerWrapper")].map(itm => outer_height(itm));

//console.log(work_items_height);

work_items_height.reduce((accumulator, currentValue, currentIndex, array) => {

	//console.log("accu: ", accumulator, "curr: ", currentValue, "accu + curr: ", accumulator + currentValue, "index: ", currentIndex);
	if(accumulator + currentValue < 1045 - outer_height(document.getElementsByTagName("header")[0])) return accumulator + currentValue;
	else
	{	
		let next_page= 0; 
		//console.log("break block: ", document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex]);
		let sub_blocks= [...document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex].querySelectorAll("[name=sub_block]")];
		
		let upper_height= outer_height(document.getElementsByTagName("header")[0]);

		if(sub_blocks.length > 0)
		{
			//console.log(sub_blocks);

			for(let i= 0; i < currentIndex; i++)
				upper_height= upper_height + array[i];
			//console.log("leg room: ", 1045 - upper_height);
			//console.log("upper_height: ",  upper_height);
			sub_blocks.map(itm => outer_height(itm)).reduce((accu, curr, index, arr) => {
				//console.log(upper_height, accu, curr, accu + curr);
				if(accu + curr < 1045 - upper_height) return accu + curr;
				else
				{
					//console.log("break sub block: ", sub_blocks[index]);
					sub_blocks[index].classList.toggle("break_force_before");

					let foot= document.createElement("div");
					foot.innerHTML= '<span>Made with</span> <img src="" alt="" class= "flipr_logo"/>';
					foot.classList.add("footer");
					foot.style.marginTop= 1045 - upper_height - accu + "px"; 
					sub_blocks[index].parentElement.insertBefore(foot, sub_blocks[index]);

					console.log("footer space: ", 1045 - upper_height - accu);

					let head= document.createElement("div");
					head.classList.add("break");
					sub_blocks[index].parentElement.insertBefore(head, sub_blocks[index]);

					arr.slice(index - 1).reduce((ca, cu) => {
						//console.log("remainder1:", cu);
						next_page= next_page + cu;
						return ca + cu;
					})
					
					return 1;
				}
			});
			next_page= [...document.querySelectorAll(".cv_mainContent-innerWrapper")].map(itm => outer_height(itm)).slice(currentIndex).reduce((ac, cu) => {
				//console.log("remainder2:", cu);
				//next_page= next_page + ac;
				return cu + ac;
			})
			//console.log(next_page);
			document.getElementById("footer").style.marginTop= 1045 - next_page + "px";
		}
		else 
		{
			for(let i= 0; i < currentIndex; i++)
			{
				//console.log(upper_height, i, array[i], upper_height + array[i]);
				upper_height= upper_height + array[i];
			}
			//console.log("upper height: ", upper_height);
			//console.log("leg room: ", 1045 - upper_height);

			let foot= document.createElement("div");
			foot.innerHTML= '<span>Made with</span> <img src="" alt="" class= "flipr_logo"/>';
			foot.classList.add("page_footer");
			foot.style.marginTop= 1045 - upper_height + "px"; 
			document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex].parentElement.insertBefore(foot, document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex]);

			let head= document.createElement("div");
			head.classList.add("break");
			document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex].parentElement.insertBefore(head, document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex]);
		
			let border= document.createElement("div");
			border.classList.add("border");
			document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex].parentElement.insertBefore(border, document.querySelectorAll(".cv_mainContent-innerWrapper")[currentIndex]);

			//console.log("currentIndex: " , currentIndex);
			/*[...document.querySelectorAll(".cv_mainContent-innerWrapper")].map(itm => outer_height(itm)).slice(currentIndex).reduce((ac, cu) => {
				//console.log("remainder3:", ac);
				next_page= next_page + ac + cu;
				return ac + cu;
			})*/
			
			next_page= [...document.querySelectorAll(".cv_mainContent-innerWrapper")].map(itm => outer_height(itm)).slice(currentIndex).reduce((ac, cu) => {
				//console.log("remainder2:", cu);
				//next_page= next_page + ac;
				return cu + ac;
			})

			//console.log("next_page:", next_page);
			document.getElementById("footer").style.marginTop= 1045 - next_page + "px";
		}
		return 1;
	}
})

document.querySelectorAll(".flipr_logo").forEach(itm => itm.src= fliprLogo);

/*let onrendered_callback= e => {
	let body = document.body,
    html = document.documentElement;

	let height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
	let width= Math.max(
	    document.documentElement["clientWidth"],
	    document.body["scrollWidth"],
	    document.documentElement["scrollWidth"],
	    document.body["offsetWidth"],
	    document.documentElement["offsetWidth"]
	);
	//console.log(height, width);
}*/
//let dim= document.getElementById('container').getBoundingClientRect();
////console.log(dim);


var element = document.getElementById('container');
var opt = {
	margin: 1,
	filename: 'CV.pdf',
	image: 
	{ 
		type: 'jpeg', quality: 0.98 
	},
	html2canvas: 
	{ 
		scale: 2,
		allowTaint: true,
		useCORS: true
	},
	jsPDF:  
	{
		unit: 'cm', 
		format: "A4", 
		orientation: 'portrait' 
	},
	mode: ['css']
}
html2pdf().set(opt).from(element).save();

}

export default script;