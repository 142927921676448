import React, { Component } from 'react';
import './sidebar.css';
import { connect } from 'react-redux';
import fliprlogo from './../../../img/group-2.png'
import profile from './../../../img/shape-2.png'
import education from './../../../img/group-11.png'
import experience from './../../../img/shape-3.png'
import skills from './../../../img/shape-4.png'
import extracurr from './../../../img/group-5.png'
import language from './../../../img/language.png'

class Sidebar extends Component {

    currentstateHandler = (currentstate) => {
        this.props.onCurrentstate(currentstate)
        window.scrollTo(0, 0);
    }



    render() {
        return (
            <nav className="sidebar">
                <div className="form-progress">
                    <div className="profile oncursor" onClick={() => this.currentstateHandler('proinfo')}>
                        {(!this.props.proinfo && this.props.current_state !== 'proinfo') ?
                            <div className="progress-link">
                                <span className="progress-link-dot"> </span>
                                <span className="progress-item">Profile Information</span>
                            </div> : null}
                        {(this.props.proinfo && this.props.current_state !== 'proinfo') ?
                            <div className="progress-link">
                                <span className="progress-link-gdot"> </span>
                                <span className="progress-item">Profile Information</span>
                            </div> : null}
                        {this.props.current_state === 'proinfo' ?
                            <div className="progress-link backimage">
                                <span className="progress-link-idot"><img className="logo-fix" src={profile} /></span>
                                <span className="progress-item-i">Profile Information</span>
                            </div> : null}
                    </div>
                    <div className="profile oncursor" onClick={() => this.currentstateHandler('educ')}>
                        {(!this.props.educ && this.props.current_state !== 'educ') ?
                            <div className="progress-link">
                                <span className="progress-link-dot"> </span>
                                <span className="progress-item">Education</span>
                            </div> : null}
                        {(this.props.educ && this.props.current_state !== 'educ') ?
                            <div className="progress-link">
                                <span className="progress-link-gdot"> </span>
                                <span className="progress-item">Education</span>
                            </div> : null}
                        {this.props.current_state === 'educ' ?
                            <div className="progress-link backimage">
                                <span className="progress-link-idot"><img src={education} /></span>
                                <span className="progress-item-i">Education</span>
                            </div> : null}
                    </div>
                    <div className="profile oncursor" onClick={() => this.currentstateHandler('expe')}>
                        {(!this.props.expe && this.props.current_state !== 'expe') ?
                            <div className="progress-link">
                                <span className="progress-link-dot"> </span>
                                <span className="progress-item">Experience</span>
                            </div> : null}
                        {(this.props.expe && this.props.current_state !== 'expe') ?
                            <div className="progress-link">
                                <span className="progress-link-gdot"> </span>
                                <span className="progress-item">Experience</span>
                            </div> : null}
                        {this.props.current_state === 'expe' ?
                            <div className="progress-link backimage">
                                <span className="progress-link-idot"><img className="logo-fix" src={experience} /></span>
                                <span className="progress-item-i">Experience</span>
                            </div> : null}
                    </div>
                    <div className="profile oncursor" onClick={() => this.currentstateHandler('skills')}>
                        {(!this.props.skills && this.props.current_state !== 'skills') ?
                            <div className="progress-link">
                                <span className="progress-link-dot"> </span>
                                <span className="progress-item">My Skills</span>
                            </div> : null}
                        {(this.props.skills && this.props.current_state !== 'skills') ?
                            <div className="progress-link">
                                <span className="progress-link-gdot"> </span>
                                <span className="progress-item">My Skills</span>
                            </div> : null}
                        {this.props.current_state === 'skills' ?
                            <div className="progress-link backimage">
                                <span className="progress-link-idot"><img className="logo-fix" src={skills} /></span>
                                <span className="progress-item-i">My Skills</span>
                            </div> : null}
                    </div>
                    <div className="profile oncursor" onClick={() => this.currentstateHandler('ex_curr')}>
                        {(!this.props.ex_curr && this.props.current_state !== 'ex_curr') ?
                            <div className="progress-link">
                                <span className="progress-link-dot"> </span>
                                <span className="progress-item">Extra-Curricular</span>
                            </div> : null}
                        {(this.props.ex_curr && this.props.current_state !== 'ex_curr') ?
                            <div className="progress-link">
                                <span className="progress-link-gdot"> </span>
                                <span className="progress-item">Extra-Curricular</span>
                            </div> : null}
                        {this.props.current_state === 'ex_curr' ?
                            <div className="progress-link backimage">
                                <span className="progress-link-idot"><img className="logo-fix" src={extracurr} /></span>
                                <span className="progress-item-i">Extra-Curricular</span>
                            </div> : null}
                    </div>
                    <div className="profile oncursor" onClick={() => this.currentstateHandler('language')}>
                        {(!this.props.language && this.props.current_state !== 'language') ?
                            <div className="progress-link">
                                <span className="progress-link-dot"> </span>
                                <span className="progress-item">Language</span>
                            </div> : null}
                        {(this.props.language && this.props.current_state !== 'language') ?
                            <div className="progress-link">
                                <span className="progress-link-gdot"> </span>
                                <span className="progress-item">Language</span>
                            </div> : null}
                        {this.props.current_state === 'language' ?
                            <div className="progress-link backimage">
                                <span className="progress-link-idot"><img className="logo-fix" src={language} /></span>
                                <span className="progress-item-i">Language</span>
                            </div> : null}
                    </div>
                </div>
                <div className="sidebar-logo test-center">
                    <span className="powered">Powered By</span>
                    <img className="logo" src={fliprlogo} />
                </div>
            </nav >
        );
    }
}


const mapStateToProps = state => {
    return {
        proinfo: state.common_reducer.proinfo,
        educ: state.common_reducer.educ,
        expe: state.common_reducer.expe,
        skills: state.common_reducer.skills,
        ex_curr: state.common_reducer.ex_curr,
        language: state.common_reducer.language,
        current_state: state.common_reducer.current_state,
        test: state.common_reducer.extra_curricular
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onCurrentstate: ((current_state) => dispatch({ type: 'current_state', value: { current_state: current_state } }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);