const initialState = {
    experience_item: {
        experience_type: 'Internsihp',
        role: '',
        company_name: '',
        department: '',
        start: '',
        end: '',
        reporting_manager: '',
        contact_no: '',
        responsibilities: ''
    },
    experience: [],
    experience_latest: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'upload_data':
            return {
                ...state,
                experience: action.value.upload_data.experience
            }
        case 'experience':
            return {
                ...state,
                experience: action.value.experience
            }

        case 'experience_latest':
            return {
                ...state,
                experience_latest: action.value.experience_latest
            }

        case 'experience_item':
           
            return {
                ...state,
                experience_item: {
                    ...state.experience_item,
                    experience_type: action.value.experience_item.experience_type,
                    role: action.value.experience_item.role,
                    company_name: action.value.experience_item.company_name,
                    department: action.value.experience_item.department,
                    start: action.value.experience_item.start,
                    end: action.value.experience_item.end,
                    reporting_manager: action.value.experience_item.reporting_manager,
                    contact_no: action.value.experience_item.contact_no,
                    responsibilities: action.value.experience_item.responsibilities
                }

            }
        default:
            return state;
    }
};

export default reducer;