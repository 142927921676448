import React, { Component } from 'react';

//bootstrap npm package start
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
//bootstrap npm package start
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import Html2pdf from './html_2_pdf/html_2_pdf.js';


//Route order is very important
class App extends Component {
    state = {
        toDashboard: false,
        before: 'AEu4IL0QDIE947klaVYKBeLw9PcZbquNP72tIJKBNNpCSjuVQOvQX21xgfSw7BaRgoazBY9teN03ZGvOfllDsv_kZ-7Y6lQzZpu2UcCz-DrvyR7e4_wNqpGrHQENrP-qQTXYzX3EtWMyKhl86oz04DAXl3ZjyjiUiGSD0hBy60T9tbVo_OKtOew9vIWFGzK4SL_Z_NAyrs04kY0XlGIjzYppreYJsw1h3A',
        later: ' AEu4IL0QDIE947klaVYKBeLw9PcZbquNP72tIJKBNNpCSjuVQOvQX21xgfSw7BaRgoazBY9teN03ZGvOfllDsv_kZ-7Y6lQzZpu2UcCz-DrvyR7e4_wNqpGrHQENrP-qQTXYzX3EtWMyKhl86oz04DAXl3ZjyjiUiGSD0hBy60T9tbVo_OKtOew9vIWFGzK4SL_Z_NAyrs04kY0XlGIjzYppreYJsw1h3A'
    }
    handleSubmit = () => {
        this.props.history.push(`/student_form`)
    }
    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to='/student_form' />
        }
        return (
            <div>
                <Html2pdf/>
                <button onClick={this.handleSubmit}>test</button>
            </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userid: state.common_reducer.userId,
        user_databaseId: state.common_reducer.user_databaseId
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuthState: ((token, userId, user_databaseId) => dispatch({ type: 'authcheck', value: { token: token, userId: userId, user_databaseId: user_databaseId } }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
