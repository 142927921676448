import React, { Component } from 'react';
import Axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import './Login.css';
import { connect } from 'react-redux';
import college_logo from './../../../img/college_logo.jpg'
import { firebase_api_key } from './../../env/firebase '
import flipr_logo from './../../../img/group-2.png'
import { firebase_database_base_url } from './../../env/firebase '

class Login extends Component {

    state = {
        email: "",
        emailError: false,
        password: "",
        passwordError: false,
        showlabel: false,
        redirect: false
    }

    emailhandler = (e) => {
        this.setState({
            email: e.target.value,
            showlabel: true
        })

        if (this.state.password === "") {
            this.setState({
                passwordError: true
            })
        }
        const re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;
        if (e.target.value !== "" && re.test(e.target.value)) {
            this.setState({ emailError: false })
        }
        else {
            this.setState({ emailError: true })
        }
    }

    passwordhandler = (e) => {
        this.setState({
            password: e.target.value,
            showlabel: true
        })

        if (this.state.email === "") {
            this.setState({
                emailError: true
            })
        }

        if (e.target.value !== "") {
            this.setState({ passwordError: false })
        }
        else {
            this.setState({ passwordError: true })
        }

    }

    send = (e) => {
        e.preventDefault();
        const data = {
            email: this.state.email,
            password: this.state.password,
            returnSecureToken: true
        }
        Axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + firebase_api_key, data)
            .then(res => {

                // data save on local storage 
                const expirationDate = new Date(new Date().getTime() + res.data.expiresIn * 1000);
                this.props.onAuth(res.data.idToken, res.data.localId, expirationDate)
                localStorage.setItem('token', res.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', res.data.localId)
                localStorage.setItem('refreshToken', res.data.refreshToken)
                this.props.refresh_token();

                Axios.get(firebase_database_base_url + 'users.json?auth=' + res.data.idToken + '&orderBy="userId"&equalTo="' + res.data.localId + '"')
                    .then(res => {

                        if (Object.keys(res.data).length === 0) {
                            const data = {
                                userId: localStorage.getItem('userId')
                            }


                            Axios.post(firebase_database_base_url + 'users.json?auth=' + localStorage.getItem('token'), data)
                                .then(res => {

                                    localStorage.setItem('user_databaseId', res.data.name)
                                    this.props.onUser_databaseId(res.data.name)
                                })
                                .catch(err => alert('Something went wrong'))
                        }
                        else {
                            localStorage.setItem('user_databaseId', Object.keys(res.data)[0])
                            this.props.onUser_databaseId(Object.keys(res.data)[0])
                        }
                        // if (this.props.token !== null) {
                        // this.props.history.push('/Student_form')
                        this.setState({ redirect: true })
                        // }
                    })
                    .catch(err => alert('check your network connection'))
            })
            .catch(err => alert('Email or Password wrong'))

    }


    disbledHandler = () => {

        if (this.state.email !== "" && this.state.password !== ""
            && !this.state.emailError && !this.state.passwordError) {
            return false
        }
        else {
            return true
        }
    }

    forgotpasswordHandler = () => {
        const re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,5})$/;
        if (this.state.email === "" || this.state.emailError || !re.test(this.state.email)) {
            alert('Enter only email address')
            this.setState({ emailError: true })
            return
        }
        else {
            this.setState({ emailError: false })
        }
        const data1 = {
            requestType: 'PASSWORD_RESET',
            email: this.state.email,
        }
        Axios.post('https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=' + firebase_api_key, data1)
            .then(res => {
                alert('Password Reset link has been sent to your email')
            })
            .catch(err => alert('Something Went Wrong'))
    }


    render() {

        return (
            <div>
                {this.state.redirect ? <Redirect to="/student_form" /> : null}
                <div className="text-center login_logo_height">
                    <img className="inst-logo" src={flipr_logo} />
                </div>
                <div className="card-height">
                    <div className="card card-custom">
                        <div className="card-body">
                            <h2 className="text-center login-header">Student Login</h2>
                            <form onSubmit={(e) => this.send(e)}>
                                <div className="form-group group-margin">
                                    <label htmlFor="exampleInputEmail1"
                                        style={{
                                            opacity: this.state.showlabel ? '1' : '0'
                                        }}>Email*</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" value={this.state.email} placeholder="Enter email"
                                        autoComplete="off" onChange={(e) => this.emailhandler(e)} />
                                    <p className="error" style={{
                                        opacity: this.state.emailError ? '1' : '0'
                                    }} >Enter  email</p>

                                </div>
                                <div className="form-group group-margin">
                                    <label htmlFor="exampleInputPassword1"
                                        style={{
                                            opacity: this.state.showlabel ? '1' : '0'
                                        }}>Password*</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1"
                                        placeholder="Password" value={this.state.password} onChange={(e) => this.passwordhandler(e)} />
                                    <p className="error" style={{
                                        opacity: this.state.passwordError ? '1' : '0'
                                    }} >Enter Password</p>
                                </div>
                                <div className="text-center">
                                    <button type="submit" disabled={this.disbledHandler()} className="btn btn-primary btn-submit">Login</button>
                                </div>
                                <div className="text-center forgot_password_div">
                                    <Link to="" className="forgot_password"><span onClick={this.forgotpasswordHandler}>Forgot Password</span> </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="new_user_link text-center">
                        <span>Are you a New User? <Link to="/signup"> Signup here </Link></span>

                    </div>
                </div >
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userId: state.common_reducer.userId
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuth: ((token, userId, expirationDate) => dispatch({ type: 'login', value: { token: token, userId: userId, expirationDate: expirationDate } })),
        onUser_databaseId: ((user_databaseId) => dispatch({ type: 'user_databaseId', value: { user_databaseId: user_databaseId } }))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Login);