const initialState = {
    token: null,
    userId: null,
    user_databaseId: null,
    expirationDate: null,
    refreshToken: null,
    proinfo: false,
    educ: false,
    expe: false,
    skills: false,
    ex_curr: false,
    language: false,
    current_state: 'proinfo',
    form_progress_counter: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'authcheck':
            return {
                ...state,
                token: action.value.token,
                userId: action.value.userId,
                user_databaseId: action.value.user_databaseId,
                expirationDate: action.value.expirationDate,
                refreshToken:action.value.refreshToken
            };

        case 'login':
            return {
                ...state,
                token: action.value.token,
                userId: action.value.userId,
                expirationDate: action.value.expirationDate
            };
        case 'user_databaseId':
            return {
                ...state,
                user_databaseId: action.value.user_databaseId
            }
        case 'current_state':
            return {
                ...state,
                current_state: action.value.current_state
            }

        //sidebar updation for green dot start

        case 'sidebar_greendot':
            let formname = action.value.formname
            return {
                ...state,
                [formname]: action.value.status
            }
        //sidebar updation for green dot end

        case 'form_progress_counter':

            return {
                ...state,
                form_progress_counter: action.value.form_progress_counter
            }



        default:
            return state;
    }
};

export default reducer;