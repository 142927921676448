import React from 'react';
import flipr_logo from './fliprlogo.js';

let Html2Pdf= (props) => {

    let data= props.data;
    let cv_image_src= data.profile_info.image ? data.profile_info.image : "";
    let cv_basicDetails_name= data.profile_info.firstname.toUpperCase();
    let cv_basicDetails_qualification= data.education.graduation ? (data.education.graduation.gr_degree_program + ', ' + data.education.graduation.gr_specialization) : ' ';
    let cv_basicDetails_phone= data.profile_info.mobileno;
    let cv_basicDetails_email= data.profile_info.email;
    let cv_basicDetails_linkdin= data.profile_info.linkedin_profile;

    let education_template= [], 
    employment_template= [], 
    extra_curricular_template= [], 
    skills_list_template= [], 
    Language_template= [], 
    personal_template= [];        

    Object.keys(data.education).forEach((itm, indx) => {
        switch(itm)
        {   
            case "graduation" :
            {   
                if(Object.values(data.education.graduation).reduce((accumulator, currentValue) => accumulator.length > 1 || currentValue.length > 1))
                {
                    education_template.push(
                    <div className="cv_mainContent-education-details break_avoid_inside" name= "sub_block" key= "1">
                        <p className="cv_mainContent-collegeDetails">{data.education.graduation.gr_degree_program} &nbsp; | &nbsp; {data.education.graduation.gr_year_graduation}</p>
                        <p className="cv_mainContent-collegeDetails">{data.education.graduation.gr_specialization}</p>
                        <p className="cv_mainContent-collegeDetails">{data.profile_info.collegename}</p>
                        <p className="cv_mainContent-collegeDetails">Grade: {data.education.graduation.gr_overall_percentage}</p>
                    </div>)
                }
                break;
            }
            case "twelfth" :
            {
                if(Object.values(data.education.twelfth).reduce((accumulator, currentValue) => accumulator.length > 1 || currentValue.length > 1))
                {
                    education_template.push(
                    <div className="cv_mainContent-education-details break_avoid_inside" name= "sub_block" key= "2">
                        <p className="cv_mainContent-collegeDetails">{data.education.twelfth.tw_education_type} &nbsp; | &nbsp; {data.education.twelfth.tw_board} &nbsp; | &nbsp; {data.education.twelfth.tw_year_completion}</p>
                        <p className="cv_mainContent-collegeDetails">Overall Grade: {data.education.twelfth.tw_overall_percentage}</p>
                        <p className="cv_mainContent-collegeDetails">Math score: {data.education.twelfth.tw_math_score}</p>
                        <p className="cv_mainContent-collegeDetails">Physics score: {data.education.twelfth.tw_physics_score}</p>
                    </div>)
                }
                break;
            }
            case "tenth" :
            {
                if(Object.values(data.education.tenth).reduce((accumulator, currentValue) => accumulator.length > 1 || currentValue.length > 1))
                {
                     education_template.push(
                    <div className="cv_mainContent-education-details break_avoid_inside" name= "sub_block" key= "3">
                        <p className="cv_mainContent-collegeDetails">{data.education.tenth.te_education_type} &nbsp; | &nbsp; {data.education.tenth.te_board} &nbsp; | &nbsp; {data.education.tenth.te_year_completion}</p>
                        <p className="cv_mainContent-collegeDetails">Overall Grade: {data.education.tenth.te_overall_percentage}</p>
                        <p className="cv_mainContent-collegeDetails">Math score: {data.education.tenth.te_math_score}</p>
                        <p className="cv_mainContent-collegeDetails">Physics score: {data.education.tenth.te_physics_score}</p>
                    </div>)
                }
                break;
            }
            default: break;
        }
    });

    if(data.experience)
    {
        employment_template= Object.values(data.experience).map((itm, indx) => {
            return (
            <div className="cv_mainContent-work-details break_avoid_inside" name= "sub_block" key= "indx">
                <p className="cv_mainContent-workDescription">
                    <span className="cv_mainContent-workDescription-position">{itm.experience_type} &nbsp;  | &nbsp; </span>
                    <span className="cv_mainContent-workDescription-duration">{itm.start} to {itm.end}</span>
                </p>
                <p className="cv_mainContent-workDescription">{itm.company_name}</p>
                <p className="cv_mainContent-workDescription">{itm.responsibilities}</p>
            </div>)
        });
    }

    if(data.extra_curricular)
    {
        Object.values(data.extra_curricular).map((itm, index) => {
            extra_curricular_template.push(
            <div className="cv_mainContent-extra-curr" key= {index}>
                <p className="cv_mainContent-workDescription">{itm.cause} &nbsp; |  {itm.activity_type}&nbsp; </p>
                <p className="cv_mainContent-workDescription">{itm.organization}</p>
            </div>)
        });
    }

     if(data.skills)
    {
        skills_list_template= Object.values(data.skills).map((itm, index) => <li className="cv_mainContent-skills" key= {index}>{itm.skills}</li>)     
        skills_list_template= <ul className="cv_mainContent-skillsList">{skills_list_template}</ul>;
    }

     if(data.languages)
    {
        Object.values(data.languages).forEach( (itm, index) => {
            let read= <span key= "1">Read &nbsp;</span>, write= <span key= "2">Read &nbsp;</span>, speak= <span key= "3">Speak &nbsp;</span>
            let temp= [itm.read ? read : null, itm.write ? write: null, itm.speak ? speak : null]
            Language_template.push( 
            <div className="cv_mainContent-languageSkills" key= {index}>
                <span className="cv_mainContent-language">{itm.languages}</span>
                <span className="cv_mainContent-languageProficiency">{temp}</span>
            </div>)
        });
    }

    personal_template= ( 
    <div className="cv_mainContent-work-details">
        <p className="cv_mainContent-collegeDetails">DOB: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{data.profile_info.dob}</p>
        <p className="cv_mainContent-collegeDetails">Location: &nbsp; {data.profile_info.city}</p>
        <p className="cv_mainContent-collegeDetails">Gender: &nbsp; &nbsp; &nbsp;{data.profile_info.gender}</p>
    </div>)

    return(
        <div className="main-div_pdf" id= "container">
            <header>
                <div id= "basic_info">
                    <h1 id= "name">{cv_basicDetails_name}</h1>
                    <h2 id= "degree">{cv_basicDetails_qualification}</h2>
                    <ul id= "contact">
                        <li><span >Phone: </span><span className= "values">{cv_basicDetails_phone}</span></li>
                        <li><span >Email: </span><span className= "values">{cv_basicDetails_email}</span></li>
                        <li><span >Linkdin:  </span><span className= "values">{cv_basicDetails_linkdin}</span></li>
                    </ul>
                </div>
            </header>
            <div className= "border"></div>
            <div className="cv_mainContent">
                <div className="cv_mainContent-innerWrapper">
                    <h1 className="cv_mainContent-heading">MY SKILLS</h1>
                     {skills_list_template}
                </div>
                <div className="cv_mainContent-innerWrapper break_avoid_inside" name= "sub_block">
                    <h1 className="cv_mainContent-heading">LANGUAGE PROFICENCY</h1>
                    {Language_template}
                </div>
                <div className="cv_mainContent-innerWrapper">
                    <h1 className="cv_mainContent-heading">WORK EXPERIENCE</h1>
                    {employment_template}
                </div>
                <div className="cv_mainContent-innerWrapper break_avoid_inside" name= "sub_block">
                    <h1 className="cv_mainContent-heading">PERSONAL INFORMATION</h1>
                    {personal_template}
                </div>
                <div className="cv_mainContent-innerWrapper">
                    <h1 className="cv_mainContent-heading">EDUCATION</h1>
                    {education_template}
                </div>
                <div className="cv_mainContent-innerWrapper break_avoid_inside" name= "sub_block">
                    <h1 className="cv_mainContent-heading">EXTRA CURRICULAR ACTIVITIES</h1>
                    {extra_curricular_template}
                </div>
                <div className="page_footer" id= "footer">
                    <span>Made with</span> <img src= {flipr_logo} alt="Flipr" className= "flipr_logo"/>
                </div> 
            </div>
        </div>)
}

export default Html2Pdf;

