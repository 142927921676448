const initialState = {
    skills: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //profile information start
        case 'upload_data':
            return {
                ...state,
                skills: action.value.upload_data.skills
            }

        case 'add_skill':
            return {
                ...state,
                skills: action.value.skills
            }
        //profile information end
        default:
            return state;
    }
};

export default reducer;