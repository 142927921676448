const initialState = {
    extra_curricular_item: {
        activity_type: 'Volunter',
        cause: '',
        organization: ''
    },
    extra_curricular: [],
    extra_curricular_latest: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        //exra curricular start
        case 'upload_data':
            return {
                ...state,
                extra_curricular: action.value.upload_data.extra_curricular
            }

        case 'extra_curricular':
            return {
                ...state,
                extra_curricular: action.value.extra_curricular
            }

        case 'extra_curricular_latest':
            return {
                ...state,
                extra_curricular_latest: action.value.extra_curricular_latest
            }
        case 'extra_curricular_item':
          
            state.extra_curricular_item = action.value.extra_curricular_item
            return {
                ...state,
                extra_curricular_item: {
                    ...state.extra_curricular_item,
                    activity_type: action.value.extra_curricular_item.activity_type,
                    cause: action.value.extra_curricular_item.cause,
                    organization: action.value.extra_curricular_item.organization
                }

            }

        //exra curricular end

        //profile information end
        default:
            return state;
    }
};

export default reducer;