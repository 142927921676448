import React, { Component } from 'react';
import Axios from 'axios';
import './signup.css';
import { connect } from 'react-redux';
import flipr_logo from './../../../img/group-2.png'
import { firebase_api_key } from './../../env/firebase '
import { firebase_database_base_url } from './../../env/firebase '

class Signup extends Component {

    state = {
        FirstName: "",
        FirstNameError: false,
        LastName: "",
        LastNameError: false,
        Email: "",
        EmailError: false,
        Mobile: '',
        MobileError: false,
        College: "",
        loading: false
    }




    send = (e) => {
        e.preventDefault();
        const data = {
            email: this.state.Email,
            password: 'gfchuygytfgvhugvbhjgf',
            displayName: this.state.FirstName
        }
        Axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + firebase_api_key, data)
            .then(res => {
                const userId = res.data.localId
                const token = res.data.idToken
                const password_set = {
                    requestType: 'PASSWORD_RESET',
                    email: this.state.Email
                }

                Axios.post('https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=' + firebase_api_key, password_set)
                    .then(res => {
                        alert('Password set link has been sent on your email id')
                        const profile = {
                            profile_info: {
                                image: '',
                                student_id: '',
                                firstname: this.state.FirstName,
                                lastname: this.state.LastName,
                                mobileno: this.state.Mobile,
                                dob: '',
                                email: this.state.Email,
                                gender: 'Male',
                                city: 'Banglore',
                                linkedin_profile: '',
                                collegename: this.state.College,
                                shortbio: ''
                            },
                            userId: userId
                        }

                        Axios.post(firebase_database_base_url + 'users.json?auth=' + token, profile)
                            .then(res => res)
                            .catch(err => err)

                    })
                    .catch(err => err)
            })
            .catch(err => alert('Email already Exists'))
    }




    onlynumber = (e, property) => {
        const re = /[0-9A-F:]+/g;
        if (!re.test(e.key)) {
            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }
    }

    email_valid = (itemkey, itemvalue) => {

        this.setState({ [itemkey]: itemvalue })
        var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        if (!re.test(itemvalue)) {
            this.setState({ EmailError: true })
        }
        else {
            this.setState({ EmailError: false })
        }
    }

    statechangeHandler = (itemkey, itemvalue) => {

        this.setState({ [itemkey]: itemvalue })
    }

    onlyalph = (e, property) => {
        const re = /^[a-zA-Z]*$/;


        if (!re.test(e.key)) {

            e.preventDefault()
            this.setState({ [property]: true })
        }
        else {
            this.setState({ [property]: false })
        }

    }

    disabled = () => {
        if (this.state.email !== "" && !this.state.EmailError && this.state.FirstName !== "" && !this.state.FirstNameError
            && this.state.LastName != "" && !this.state.LastNameError && this.state.Mobile !== "" && !this.state.MobileError
            && this.state.College !== "" && this.state.Mobile.length === 10) {
            return false
        }
        else {
            return true
        }
    }

    render() {
        return (
            <div>
                <div className="text-center">
                    <img className="inst-logo" src={flipr_logo} />
                </div>
                <div className="card-height">
                    <div className="card card-custom">
                        <div className="card-body">
                            <h2 className="text-center login-header">Student Signup</h2>
                            <form onSubmit={(e) => this.send(e)}>
                                <div className="form-row group-margin" style={{ height: '90px' }}>
                                    <div className="col-sm-6">
                                        <label htmlFor="firstName">First Name*</label>
                                        <input type="text" name="firstName" className="form-control" id="FirstName" placeholder="John" value={this.state.FirstName}
                                            onChange={(e) => this.statechangeHandler(e.target.id, e.target.value)}
                                            onKeyPress={(e) => this.onlyalph(e, 'FirstNameError')} />
                                        <p style={{ opacity: this.state.FirstNameError ? '1' : '0' }} className="error">Enter only Alphabate</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input type="text" name="lastName" className="form-control" id="LastName" placeholder="Doe" value={this.state.LastName}
                                            onChange={(e) => this.statechangeHandler(e.target.id, e.target.value)}
                                            onKeyPress={(e) => this.onlyalph(e, 'LastNameError')} />
                                        <p style={{ opacity: this.state.LastNameError ? '1' : '0' }} className="error">Enter only Alphabate</p>
                                    </div>
                                </div>
                                <div className="form-group group-margin">
                                    <label htmlFor="mobileNumber">Mobile*</label>
                                    <input type="text" name="Mobile" className="form-control" id="Mobile" aria-describedby="numberHelp" placeholder="+91 8675309..." value={this.state.Mobile}
                                        onChange={(e) => this.statechangeHandler(e.target.id, e.target.value)}
                                        onKeyPress={(e) => this.onlynumber(e, 'MobileError')} />
                                    <p style={{ opacity: this.state.MobileError ? '1' : '0' }} className="error">Enter only 10 Digits</p>
                                </div>
                                <div className="form-group group-margin">
                                    <label htmlFor="email">Email*</label>
                                    <input type="email" name="Email" className="form-control" id="Email" aria-describedby="emailHelp" placeholder="john@doe.com" value={this.state.Email}
                                        onChange={(e) => this.email_valid(e.target.id, e.target.value)} />
                                    <p style={{ opacity: this.state.EmailError ? '1' : '0' }} className="error">Enter valid Email address</p>
                                </div>
                                <div className="form-group group-margin">
                                    <label htmlFor="college">College*</label>
                                    <div className="collegeSearch">
                                        <input type="text" name="College" className="form-control" id="College" aria-describedby="collegeHelp" placeholder="University of doe" value={this.state.College}
                                            onChange={(e) => this.statechangeHandler(e.target.id, e.target.value)} />
                                        <button className="btn"><img alt=" " alt="" /></button>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" disabled={this.disabled()} className="btn btn-primary btn-submit"  >Signup</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >

        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userId: state.common_reducer.userId,

    }
}





export default connect(mapStateToProps)(Signup);