import React, { Component } from 'react';
import Axios from 'axios';
import './password_set.css';
import { connect } from 'react-redux';
import college_logo from './../../../img/college_logo.jpg'
import { firebase_api_key } from './../../env/firebase '
import flipr_logo from './../../../img/group-2.png'
import { Link, Redirect } from 'react-router-dom';
import { firebase_database_base_url } from './../../env/firebase '

class Password_Set extends Component {

    state = {
        password: "",
        passwordError: false,
        confirmpassword: "",
        confirmpasswordError: false,
        showlabel: false,
        oobcode: ' ',
        redirect: false
    }


    componentDidMount() {

        function getParameterByName(name) {
            name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + name + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(window.location.href);
            if (results == null)
                return "";
            else
                return decodeURIComponent(results[1].replace(/\+/g, " "));
        }
        // Get the action to complete.
        var mode = getParameterByName('mode');
        // Get the one-time code from the query parameter.
        var actionCode = getParameterByName('oobCode');
        this.setState({ oobcode: actionCode })
        // (Optional) Get the continue URL from the query parameter if available.
        var continueUrl = getParameterByName('continueUrl');
    }




    passwordhandler = (e) => {
        this.setState({
            password: e.target.value,
            showlabel: true,
            passwordError: true,
            confirmpasswordError: true
        })

        if (e.target.value !== "" && e.target.value.length > 5) {
            this.setState({ passwordError: false })
        }
        else {
            this.setState({ passwordError: true })
        }

    }

    confirmpasswordhandler = (e) => {
        this.setState({
            confirmpassword: e.target.value,
            showlabel: true
        })

        if (e.target.value !== "" && this.state.password === e.target.value && e.target.value.length > 5) {
            this.setState({ confirmpasswordError: false })
        }
        else {
            this.setState({ confirmpasswordError: true })
        }
    }


    setpassword = (e) => {
        e.preventDefault();
        const data = {
            oobCode: this.state.oobcode,
            newPassword: this.state.password
        }

        Axios.post('https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=' + firebase_api_key, data)
            .then(res => {
                const data = {
                    email: res.data.email,
                    password: this.state.password,
                    returnSecureToken: true
                }
                Axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + firebase_api_key, data)
                    .then(res => {
                        // data save on local storage 
                        const expirationDate = new Date(new Date().getTime() + res.data.expiresIn * 1000);
                        this.props.onAuth(res.data.idToken, res.data.localId, expirationDate)
                        localStorage.setItem('token', res.data.idToken);
                        localStorage.setItem('expirationDate', expirationDate);
                        localStorage.setItem('userId', res.data.localId)
                        localStorage.setItem('refreshToken', res.data.refreshToken)
                        this.props.refresh_token();

                        Axios.get(firebase_database_base_url + 'users.json?auth=' + res.data.idToken + '&orderBy="userId"&equalTo="' + res.data.localId + '"')
                            .then(res => {
                                if (Object.keys(res.data).length === 0) {
                                    const data = {
                                        userId: localStorage.getItem('userId')
                                    }

                                    Axios.post(firebase_database_base_url + 'users.json?auth=' + localStorage.getItem('token'), data)
                                        .then(res => {
                                            localStorage.setItem('user_databaseId', res.data.name)
                                            this.props.onUser_databaseId(res.data.name)
                                        })
                                        .catch(err => err)
                                }
                                else {
                                    localStorage.setItem('user_databaseId', Object.keys(res.data)[0])
                                    this.props.onUser_databaseId(Object.keys(res.data)[0])
                                }


                            })
                            .catch(err => err)
                        // if (this.props.token !== null) {
                        this.props.history.push('/Student_form')
                        // }
                        this.setState({ redirect: true })

                    })
                    .catch(err => err)
            })
            .catch(err => alert('Your request to reset your password has expired or the link has already been used'))

    }


    disbledHandler = () => {

        if (this.state.password !== "" && this.state.confirmpassword !== ""
            && !this.state.passwordError && !this.state.confirmpasswordError &&
            this.state.password === this.state.confirmpassword) {
            return false
        }
        else {
            return true
        }


    }
    render() {
        return (
            <div>
                {/* {this.state.redirect ? <Redirect to="/student_form" /> : <Redirect to="/password_set" />} */}
                <div className="text-center password_set_logo_height">
                    <img className="inst-logo" src={flipr_logo} />
                </div>
                <div className="card-height">
                    <div className="card card-custom">
                        <div className="card-body">
                            <h2 className="text-center login-header">Set Password</h2>
                            <form onSubmit={(e) => this.setpassword(e)}>
                                <div className="form-group group-margin">
                                    <label style={{
                                        opacity: this.state.showlabel ? '1' : '0'
                                    }}
                                        for="exampleInputEmail1">New Password*</label>
                                    <input type="password" className="form-control" aria-describedby="emailHelp"
                                        placeholder="New Password" value={this.state.password} onChange={(e) => this.passwordhandler(e)} />
                                    <p className="error" style={{
                                        opacity: this.state.passwordError ? '1' : '0'
                                    }} >Password length must be minimum 6</p>

                                </div>
                                <div className="form-group group-margin">
                                    <label style={{
                                        opacity: this.state.showlabel ? '1' : '0'
                                    }}
                                        for="exampleInputPassword1">Confirm Password*</label>
                                    <input type="password" className="form-control" value={this.state.confirmpassword} placeholder="Confirm Password" onChange={(e) => this.confirmpasswordhandler(e)} />
                                    <p className="error" style={{
                                        opacity: this.state.confirmpasswordError ? '1' : '0'
                                    }} >The passwords do not match.</p>
                                </div>
                                <div className="text-center">
                                    <button type="submit" disabled={this.disbledHandler()} className="btn btn-primary btn-submit">Set Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.common_reducer.token,
        userId: state.common_reducer.userId
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onAuth: ((token, userId, expirationDate) => dispatch({ type: 'login', value: { token: token, userId: userId, expirationDate: expirationDate } })),
        onUser_databaseId: ((user_databaseId) => dispatch({ type: 'user_databaseId', value: { user_databaseId: user_databaseId } })),
        onAuthState: ((token, userId, user_databaseId, expirationDate) => dispatch({
            type: 'authcheck', value: {
                token: token, userId: userId,
                user_databaseId: user_databaseId,
                expirationDate: expirationDate
            }
        }))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Password_Set);