const initialState = {
    education: {
        graduation: {
            gr_education_type: 'Graduate',
            gr_year_graduation: '',
            gr_degree_program: 'Banchelor’s_in_engineering',
            gr_specialization: '',
            gr_overall_percentage: '',
            gr_current_semster: '',
            gr_do_uncleared_arreare: 'NO',
            gr_no_uncleared_arreare: ''
        },
        tenth: {
            te_education_type: '10th Standard',
            te_year_completion: '',
            te_board: 'CBSE',
            te_overall_percentage: '',
            te_math_score: '',
            te_physics_score: '',
        },
        twelfth: {
            tw_education_type: '12th Standard',
            tw_year_completion: '',
            tw_board: 'CBSE',
            tw_overall_percentage: '',
            tw_math_score: '',
            tw_physics_score: '',
        },
        certificate: []

    },
    education_certificate_item: {
        education_type: '',
        year_certification: '',
        certification_subject: '',
        certifying_organization: '',
        score: ''
    },
    education_certificate_latest: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'upload_data':
            return {
                ...state,
                education: action.value.upload_data.education
            }
        case 'education':
            return {
                ...state,
                education: {
                    ...state.education,
                    [action.value.type]: {
                        ...state.education[action.value.type],
                        [action.value.itemkey]: action.value.itemvalue
                    }
                }

            }

        case 'education_certificate':
           
            return {
                ...state,
                education: {
                    ...state.education,
                    certificate: action.value.education_certificate
                }
            }

        case 'education_certificate_latest':
            return {
                ...state,
                education_certificate_latest: action.value.education_certificate_latest
            }
        case 'education_certificate_item':

            return {
                ...state,
                education_certificate_item: {
                    ...state.education_certificate_item,
                    education_type: action.value.education_certificate_item.education_type,
                    year_certification: action.value.education_certificate_item.year_certification,
                    certification_subject: action.value.education_certificate_item.certification_subject,
                    certifying_organization: action.value.education_certificate_item.certifying_organization,
                    score: action.value.education_certificate_item.score
                }

            }
        default:
            return state;
    }
};

export default reducer;