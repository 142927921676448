const initialState = {
    languages: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // profile information start
        case 'upload_data':
            return {
                ...state,
                languages: action.value.upload_data.languages
            }

        case 'add_language':
            return {
                ...state,
                languages: action.value.languages
            }
        //profile information end
        default:
            return state;
    }
};

export default reducer;